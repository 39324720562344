import React from 'react';
import { TopBarControl, BottomBarControl } from './toolbar_components.js';
import { ExamSelector } from './select_exam.js'
import { RecordingImageDisplay } from './recording_component.js';
import { removeDuplicates } from '../utility/helper_functions.js'
import { galenApi } from '../utility/galen-api.js'

class ExamViewerApp extends React.Component {

  constructor(props) {
    super(props);
    let params = getParamsFromPath(props.location);
    let stateFromPath = loadExamFromPath(params);
    this.state = stateFromPath;
  }

  handleBack = () => {
    this.setState({ examSelected: null, examLoaded: null, examLoadError: false, currentRecording: 0 });
  }

  handleNext = () => {
    let recordingMax = this.state.examInfo.recordings.length - 1;
    this.setState({ currentRecording: Math.min(this.state.currentRecording + 1, recordingMax) })
  }

  handlePrevious = () => {
    this.setState({ currentRecording: Math.max(this.state.currentRecording - 1, 0) })
  }

  // connects toolbar button to recording component
  handleExportSegmentRequest = () => {
    this.setState({ exportRequestPending: true });
  }

  // handles recording component response after export is completed 
  handleExportSegmentResponse = () => {
    this.setState({ exportRequestPending: false });
  }

  componentDidUpdate(previousProps, previousState) {
    if (this.state.examInfo === previousState.examInfo) return;
    // practice id is needed to get a list of exams for that practice. If URL with practice is provided, we need to 
    // get the practice id before we can request exams
    if (this.state.examInfo.practice.name !== null && this.state.examInfo.practice.id === null) this.addPracticeId();
    // if we have a selected exam, and we haven't started loading it, fetch the recordings for that exam
    if (this.state.examSelected && !this.state.examLoaded) this.getRecordings(this.state.examInfo.deviceId, this.state.examInfo.examNumber, this.state.examInfo.examNumber.date, this.state.examInfo.examNumber.time);


  }

  componentDidMount() {

    // set app height to fill up visible screen instead of overlapping mobile top and bottom menus 
    let vh = window.innerHeight * 0.01; // vh is 1% of browser window height 
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    // resize height if screen resized or rotated.
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01; // vh is 1% of browser window height
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
    // if we have deviceId and examNumber already, attempt to load the exam
    if (this.state.examSelected) {
      this.getRecordings(this.state.examInfo.deviceId, this.state.examInfo.examNumber, this.state.examInfo.examDate, this.state.examInfo.examTime);
    }
  }

  // gets the id for the currently selected practice
  addPracticeId = async () => {

    let practice = await galenApi.getPracticeId(this.props.isProd, this.props.authToken, this.state.examInfo.practice.name);
    if (practice.success) {
      let examInfo = this.state.examInfo;
      examInfo.practice = practice
      this.setState({ examInfo });
    }
  }

  setExam = (examInfo) => {
    this.setState({ examInfo: examInfo, examSelected: true })
  }

  getImageUrl = async (imagePropertyCode, deviceDataId) => {

    let response = await galenApi.getImageUrl(this.props.isProd, this.props.authToken, deviceDataId, imagePropertyCode);
    if (!response.success) {
      if (response.errorType === galenApi.error.AUTH_ERROR) {
        this.props.handleUnauthorized();
        return;
      }
      let errors = { ...this.state.errors }
      errors.hasImageLoadError = true;
      this.setState({ errors: errors });
      return;
    }
    if (imagePropertyCode === 'lci') return URL.createObjectURL(response.url);
    if (imagePropertyCode === 'surface') return URL.createObjectURL(response.url);
  }


  getRecordings = async (deviceId, examNumber, examDate, examTime) => {

    let response = await galenApi.getRecordings(this.props.isProd, this.props.authToken, deviceId, examNumber, examDate, examTime);

    if (!response.success) {
      if (response.errorType === galenApi.error.AUTH_ERROR) {
        this.props.handleUnauthorized();
        return;
      }
      this.setState({ examLoadError: true });
      return;
    }
    let recordingList = removeDuplicates(response.recordingList, ['recordingNumber'], "all");
    recordingList = await Promise.all(recordingList.map(async (element) => {
      element.lciUrl = await this.getImageUrl('lci', element.deviceDataId);
      element.surfaceUrl = await this.getImageUrl('surface', element.deviceDataId);
      return element;
    }));
    recordingList = recordingList.sort((a, b) => a.recordingNumber - b.recordingNumber)
    // find the recording number in the recording list
    let recordingIndex = -1;
    for (let i = 0; i < recordingList.length; i++) {
      if (recordingList[i].recordingNumber == this.state.currentRecording) recordingIndex = i;
    }
    if (recordingIndex == -1) recordingIndex = 0;
    this.setState({
      examInfo: {
        examTime: recordingList[0].examTime,
        examNumber: this.state.examInfo.examNumber,
        examDate: recordingList[0].examDate,
        deviceId: this.state.examInfo.deviceId,
        practice: this.state.examInfo.practice,
        recordings: recordingList,
        recordingTotal: recordingList.length
      },
      currentRecording: recordingIndex,
      examLoaded: true,
    });
  }

render() {
  let view = null;
  if (this.state.examLoadError) {
    view = (<ExamLoadError></ExamLoadError>);
  }
  else if (this.state.examLoaded) {
    let recording = this.state.examInfo.recordings[this.state.currentRecording];
    view = (
      <div className='container'>
        <TopBarControl
          handleLogout={this.props.handleLogout}
          handleBack={this.handleBack}
          isProd={this.props.isProd}></TopBarControl>
        <RecordingImageDisplay
          key={this.state.examInfo.deviceId.name + ':' + this.state.examInfo.examNumber.name + ':' + recording.recordingNumber}
          examInfo={this.state.examInfo}
          recording={recording}
          recordingNumber={recording.recordingNumber}
          handleUnauthorized={this.props.handleUnauthorized}
          handleExportSegmentResponse={this.handleExportSegmentResponse}
          exportRequestPending={this.state.exportRequestPending}
          examLoaded={this.state.examLoaded}
          authToken={this.props.authToken}
          isProd={this.props.isProd}
          currentRecording={recording.recordingNumber}
          currentRecordingIndex={this.state.currentRecording}
        >
        </RecordingImageDisplay>
        <BottomBarControl
          handlePrevious={this.handlePrevious}
          handleNext={this.handleNext}
          handleExportSegmentRequest={this.handleExportSegmentRequest}
          exportRequestPending={this.state.exportRequestPending}
          totalRecordings={this.state.examInfo.recordingTotal}
          currentRecording={this.state.currentRecording + 1}
          examInfo={this.state.examInfo}>
        </BottomBarControl>
      </div>
    )
  }
  else if (this.state.examSelected) {
    return (<div></div>)
  }
  else {
    view = (
      <ExamSelector
        isProd={this.props.isProd}
        authToken={this.props.authToken}
        setExam={this.setExam}
        examSelected={this.state.examSelected}
        handleUnauthorized={this.props.handleUnauthorized}
        selections={{
          device: this.state.examInfo.deviceId,
          exam: this.state.examInfo.examNumber,
          practice: this.state.examInfo.practice
        }}>
      </ExamSelector>
    )
  }
  return (
    <div className="exam-app container">
      {view}
    </div>
  )
}
}

export { ExamViewerApp }

function getParamsFromPath(path) {
  if (!path) return [];
  let params = path.split('/');
  params.shift();
  return params;
}

/*  Work through any provided parameters to load a selected exam.
    We are not checking if the exam exists here, we will just attempt to load it.
*/
function loadExamFromPath(params) {
  let state = emptyState();
  // if there are no params besides /examdata/ return with an empty state
  // sometimes we see a trailing empty parameter. Check for value before assigning
  // all of the urls are now the same for dev and prod, no need to check url.
  // since exam date was added, the param for currentRecording needs to be reset to the last of params.
  // because there are 2 types of urls for date, we need to seperate them
  if (params.length >= 8) {
    if (params.length < 2) return state;
    if (params[1] === "") return state;
    state.examInfo.practice.name = decodeURIComponent(params[1]);
    if (params.length < 3) return state;
    if (params[2] === "") return state;
    state.examInfo.deviceId.name = decodeURIComponent(params[2]);
    if (params.length < 4) return state;
    if (params[3] === "") return state;
    state.examInfo.examNumber.name = decodeURIComponent(params[3]);
    if (params.length < 5) return state;
    if (params[4] === "") return state;
    if (params.length < 7) return state;
    let year = params[4];
    let month = params[5];
    let date = params[6];
    let dateString = year + '/' + month + '/' + date;
    state.examInfo.examDate = dateString;
    if (params.length < 8) return state;
    let time = params[7];
    time = decodeURIComponent(time);
    state.examInfo.examTime = time;
    if (params.length < 9) {
      state.currentRecording = 0;
      state.examSelected = true;
    } else {
      state.currentRecording = decodeURIComponent(params[params.length - 1]);
      state.examSelected = true;
    }

  } else {
    if (params.length < 2) return state;
    if (params[1] === "") return state;
    state.examInfo.practice.name = decodeURIComponent(params[1]);
    if (params.length < 3) return state;
    if (params[2] === "") return state;
    state.examInfo.deviceId.name = decodeURIComponent(params[2]);
    if (params.length < 4) return state;
    if (params[3] === "") return state;
    state.examInfo.examNumber.name = decodeURIComponent(params[3]);
    if (params.length < 5) return state;
    if (params[4] === "") return state;
    let date = decodeURIComponent(params[4]);
    state.examInfo.examDate = date;
    if (params.length < 6) return state;
    let time = decodeURIComponent(params[5]);
    state.examInfo.examTime = time;
    if (params.length < 7) {
      state.currentRecording = 0;
      state.examSelected = true;
    } else {
      state.currentRecording = decodeURIComponent(params[params.length - 1]);
      state.examSelected = true;
    }
  }
  return state;
}

// utility function to set up the component state object
function emptyState() {
  return {
    examInfo: {
      examTime: null,
      examDate: null,
      recordings: null,
      practice: { name: null, id: null },
      deviceId: { name: null, id: null },
      examNumber: { name: null, id: null },
      recordingTotal: 0,
    },
    examSelected: false,
    examLoaded: false,
    examLoadError: false,
    currentRecording: 0,
    exportRequestPending: false
  }
}

function ExamLoadError() {
  return (<div className='exam_load_error_div'>Exam Load Error</div>)
}
