
export function removeDuplicates (array, propsToCheck, anyOrAll) {
    // This function is used for removing the duplicated exams.
    let returnArray = [];
    for(let i = 0; i < array.length; i++) {
        let isDup = false;
        for(let j = 0; j < returnArray.length; j++) {
        
        let isSameAny = false;
        let isSameAll = true; 
    
        for(let k = 0; k < propsToCheck.length; k++) {
            if (array[i][propsToCheck[k]] === returnArray[j][propsToCheck[k]]) {
            isSameAny = true;
            }
            else isSameAll = false;
        }
        if (anyOrAll === "any" && isSameAny) isDup = true;
        if (anyOrAll === "all" && isSameAll) isDup = true;
        }
        if (!isDup) returnArray.push(array[i]);
    }
    return returnArray;
}

