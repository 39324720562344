import React from 'react';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useLocation,
} from 'react-router-dom';
import { LoginControl } from './components/login_components.js';
import { ExamViewerApp } from './components/exam_viewer_component';

import './index.css';

class ExamViewerAppContainer extends React.Component {
	
	constructor(props) {
		super(props);
		let location = process.env.REACT_APP_ENV;
		// attempt to retrieve auth token from localStorage
		let storedAuthToken = localStorage.getItem(location);
		if(storedAuthToken) {
			this.state = {
				authToken: storedAuthToken,
				authLocation : location,
				state: storedAuthToken ? "exam" : "login",
				isProd: process.env.REACT_APP_ENV.valueOf()=='dev'.valueOf()?false:true
			};
		}
		else {
			this.state = {
				authToken: null,
				state: "login",
				authLocation : location,
			};
		}

  }
	
	handleLogin = (token) => {
		this.setState({authToken:token, state:"exam"});
		localStorage.setItem(this.state.authLocation,token);
	}

	handleLogout = () => {
		this.setState({authToken:null, state:"login"});
		localStorage.removeItem(this.state.authLocation);
	  }

	 handleUnauthorized = () => {
		this.setState({examInfo:null, authToken:null, state:"login"});
		localStorage.removeItem(this.state.authLocation);
	}

	render() {
		
		const isLoggedIn = this.state.state !== "login";
		// read which instance this app is in from environment variables.
		const isProd = process.env.REACT_APP_ENV.valueOf()=='dev'.valueOf()?false:true;
		// prepare route for logging in. If user is logged in, send them back to their previous location.
		const LoginRoute = (props) => {
			const location = useLocation();
			let from;
			if(location.state) {
				from = location.state.pathname
			} else {
				from = '/';
			}
			if (isLoggedIn) return <Navigate to={from} replace/> ;
			else return <div className='container'><LoginControl isProd={isProd} handleLogin={this.handleLogin} handleUnauthorized={this.handleUnauthorized}/></div>;
		} 
		
		// check if user is logged in before sending them to a authorization required location
		const PrivateRoute = (props) => {
			const location = useLocation();
			if (props.isLoggedIn) return <div className='container'><ExamViewerApp location={location.pathname} isProd={isProd} authToken = {props.authToken} handleLogout={this.handleLogout} handleUnauthorized={this.handleUnauthorized}/></div> ;
			else return <Navigate to={'/login'} state={ location } replace/>;
		}
		
		return (
				<Routes>
					<Route path='/login' element={<LoginRoute isLoggedIn={isLoggedIn} isProd={isProd}/>}></Route>
					<Route path='/*' element={<PrivateRoute isLoggedIn={isLoggedIn} isProd={isProd} authToken={this.state.authToken} />}></Route>
				</Routes>
		)
	} 

}

function ExamViewerAppRouter (props) {
	
	return (
		<Router>
			<Routes>
				<Route path='/*' element={<ExamViewerAppContainer/>}></Route>
			</Routes>
		</Router>
	)
}

ReactDOM.render(
  <React.StrictMode>
	<ExamViewerAppRouter />
  </React.StrictMode>,
  document.getElementById('root')
);

